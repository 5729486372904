import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    // const { handleToggle, sidebarOption } = this.state

    return (
      <Layout>
        <SEO title={"Privacy Policy"} titleDesc={""} titleKeywords={""} fbDes={"-"} twitDesc={"-"} seoLink={"/"} />
        <section className="">
          <article className="container pt-5">
            <div className="row align-items-center">
              <div className="col-12 text-center">
                <h1 className="h2 my-4">Privacy Policy</h1>
              </div>
            </div>
          </article>
        </section>
        {/* <!-- ============== End Top Section ============== --> */}

        {/* <!-- Start of expert Section --> */}
        <section className="policy my-4">
          <article className="container">
            <div className="row">
              <div className="col-12">
                <p className="text-center">
                  Your privacy is incredibly important to us. Any information that we collect will only be used in ways
                  for which you have consented. This Privacy Policy governs the manner in which Fusemachines collects,
                  uses, maintains and discloses information collected from users (each, a {"'User'"}) of the
                  fusemachines.com website ({"'Site'"}). This privacy policy applies to the Site and all products and
                  services offered by Fusemachines.
                </p>
                <ol type="I">
                  <li>
                    <h5 className="c-blue">Personal identification information</h5>
                    <p>
                      We may collect personal identification information from Users in a variety of ways, including, but
                      not limited to, when Users visit our site, register on the site, place an order, subscribe to the
                      newsletter, respond to a survey, fill out a form, and in connection with other activities,
                      services, features or resources we make available on our Site. Users may be asked for, as
                      appropriate, name, email address, credit card information. Users may, however, visit our Site
                      anonymously. We will collect personal identification information from Users only if they
                      voluntarily submit such information to us. Users can always refuse to supply personally
                      identification information, except that it may prevent them from engaging in certain Site related
                      activities. Fusemachines does not intentionally collect information from children under the age of
                      thirteen (13).
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">Non-personal identification information</h5>
                    <p>
                      We may collect non-personal identification information about Users whenever they interact with our
                      Site. Non-personal identification information may include the browser name, the type of computer
                      and technical information about Users means of connection to our Site, such as the operating
                      system and the Internet service providers utilized and other similar information.
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">Web browser cookies</h5>
                    <p>
                      Our Site may use {"'cookies'"} to enhance User experience. {"User's"} web browser places cookies
                      on their hard drive for record-keeping purposes and sometimes to track information about them.
                      User may choose to set their web browser to refuse cookies, or to alert you when cookies are being
                      sent. If they do so, note that some parts of the Site may not function properly.
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">How we use collected information</h5>
                    <div>
                      Fusemachines may collect and use Users personal information for the following purposes:
                      <ul>
                        <li>
                          <p>
                            <em>To improve customer service</em>
                            <br />
                            Information you provide helps us respond to your customer service requests and support needs
                            more efficiently.
                          </p>
                        </li>

                        <li>
                          <p>
                            <em>To personalize user experience</em>
                            <br />
                            We may use information in the aggregate to understand how our Users as a group use the
                            services and resources provided on our Site.
                          </p>
                        </li>
                        <li>
                          <p>
                            <em>To improve our Site</em>
                            <br />
                            We may use feedback you provide to improve our products and services.
                          </p>
                        </li>
                        <li>
                          <p>
                            <em>To process payments</em>
                            <br />
                            We may use the information Users provide about themselves when placing an order only to
                            provide service to that order. We do not share this information with outside parties except
                            to the extent necessary to provide the service.
                          </p>
                        </li>

                        <li>
                          <p>
                            <em>To run a promotion, contest, survey or other Site feature</em>
                            <br />
                            To send Users information they agreed to receive about topics we think will be of interest
                            to them.
                          </p>
                        </li>
                        <li>
                          <p>
                            <em>To send periodic emails</em>
                            <br />
                            We may use the email address to send User information and updates pertaining to their order.
                            It may also be used to respond to their inquiries, questions, and/or other requests. If User
                            decides to opt-in to our mailing list, they will receive emails that may include company
                            news, updates, related product or service information, etc. If at any time the User would
                            like to unsubscribe from receiving future emails, they may do so by contacting us via our
                            Site.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <h5 className="c-blue">How we protect your information</h5>
                    <p>
                      We adopt appropriate data collection, storage and processing practices and security measures to
                      protect against unauthorized access, alteration, disclosure or destruction of your personal
                      information, username, password, transaction information and data stored on our Site.
                    </p>
                    <p>
                      Sensitive and private data exchange between the Site and its Users happens over a SSL secured
                      communication channel and is encrypted and protected with digital signatures.
                    </p>
                    <p>
                      Fusemachines uses the services of third party vendors for storage, network and technical services.
                    </p>
                    <p>
                      Personally identifying information may be transferred outside of your home country as part of our
                      provision of services to you.
                    </p>
                    <p>
                      When required by law (under governmental order, warrant or subpoena), or when Fusemachines
                      believes that disclosure is reasonably necessary to protect the rights of Fusemachines or third
                      parties, personally identifying information may be disclosed.
                    </p>
                    <p>
                      Fusemachines reserves to right to publish correspondence and feedback sent to us in order to help
                      us respond to the correspondence or to help other users.
                    </p>
                    <p>
                      Should Fusemachines be acquired or merged with another organization, your information may be
                      transferred. We will notify you if your information is to be subject to a different privacy
                      policy.
                    </p>
                    <p>
                      Should Fusemachines cease trading or file for bankruptcy, information held about you will not be
                      transferred to a third party.
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">Sharing your personal information</h5>
                    <p>
                      We do not sell, trade, or rent Users personal identification information to others. We may share
                      generic aggregated demographic information not linked to any personal identification information
                      regarding visitors and users with our business partners, trusted affiliates and advertisers for
                      the purposes outlined above.
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">Changes to this privacy policy</h5>
                    <p>
                      fusemachines has the discretion to update this privacy policy at any time. When we do, we will
                      revise the updated date at the bottom of this page. We encourage Users to frequently check this
                      page for any changes to stay informed about how we are helping to protect the personal information
                      we collect. You acknowledge and agree that it is your responsibility to review this privacy policy
                      periodically and become aware of modifications.
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">Your acceptance of these terms</h5>
                    <p>
                      By using this Site, you signify your acceptance of this policy. If you do not agree to this
                      policy, please do not use our Site. Your continued use of the Site following the posting of
                      changes to this policy will be deemed your acceptance of those changes.
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">Your rights</h5>
                    <p>
                      Applicable data protection law may provide you with rights to access the personal information that
                      we hold about you and certain related information.
                    </p>

                    <p>
                      You may also require us to correct or delete any personal information that you have provided to
                      us. Please note that we may not be able to delete your personal information except by also
                      deleting your account (if any). We may not accommodate a request to change information if we
                      believe the change would violate any law or legal requirement or cause the information to be
                      incorrect.
                    </p>

                    <p>
                      If you wish to exercise any of these rights, please contact us at the email address provided
                      below.
                    </p>

                    <p>
                      You may also have rights under applicable law to register a complaint against us regarding our
                      processing of your personal information with the applicable regulatory body.
                    </p>
                  </li>

                  <li>
                    <h5 className="c-blue">Contacting us</h5>
                    <p>
                      If you have any questions about this Privacy Policy, the practices of this site, or your dealings
                      with this site, please contact us at: info@fusemachines.com
                    </p>
                  </li>

                  <p>This document was last updated on November 18, 2015.</p>
                </ol>
              </div>
            </div>
          </article>
        </section>
        {/* <!-- End of expert Section --> */}
      </Layout>
    )
  }
}

export default PrivacyPolicy
